import OffersSection from "@common/CommonSections/OffersSection/OffersSection";
import React from "react";
import { OfferSectionContainer } from "./OfferSectionWrapper.style";

const OfferSectionWrapper = () => (
    <OfferSectionContainer>
        <OffersSection />
    </OfferSectionContainer>
);

export default OfferSectionWrapper;
