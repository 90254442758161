import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const OfferSectionContainer = styled.div`
    margin: 0 -16rem;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin: 0 -7rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin: 0 -1.5rem;
    }
`;
